#shopaghetti-app-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  /* z-index: 2147483647; */
}
  #shopaghetti-app{
    width: 32rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    /* z-index: 2147483647; */
    position: relative;
    background-color: #fff;
    transform-origin: 50% 50%;
    box-sizing: border-box;
    border: none;
    border-radius: 20px;
    box-shadow:0 -3px 12px 0 rgba(0,0,0,.1); 
  }
  
  @media only screen and (max-width: 749px) {
    #shopaghetti-app {
      margin: 0 .8rem;
    }
    #shopaghetti-app .shopaghetti-main-title{
      font-size: 1.9rem;
    }
  }
  
  .shopaghetti-header-wrap{
    width: 100%;
    height: 8rem;
    border: none;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
  }
  
  .shopaghetti-header-wrap .shopaghetti-cancel-btn{
    padding: 10px;
    position: absolute;
    top: .8rem;
    right: .8rem;
    color: white;
    background-color: transparent;
    border: none;
    outline: none;
    background-image: url("data:image/svg xml;charset=utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'20\' height=\'20\' viewBox=\'0 0 20 20\'><path fill=\'#FFF\' fill-rule=\'nonzero\' d=\'M11.06 10l3.713 3.712a.75.75 0 0 1-1.06 1.061L10 11.061l-3.712 3.712a.75.75 0 0 1-1.061-1.06L8.939 10 5.227 6.288a.75.75 0 1 1 1.06-1.061L10 8.939l3.712-3.712a.75.75 0 0 1 1.061 1.06L11.061 10z\'/></svg>");
    background-repeat: no-repeat;
    /* z-index: 2147483647; */
  }
  .shopaghetti-header-wrap .shopaghetti-image{
    width: 8rem;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    padding: 20px;
  }
  
  .shopaghetti-main-title{
    font-family: "Dancing Script" !important;
    font-size: 2.4rem;
    margin: 0.5rem;
  }
  .shopaghetti-main-title,.shopaghetti-copy-btn,.shopaghetti-nav{
    margin-top: .6rem;
  }
  .shopaghetti-main-title, .shopaghetti-main-content, .shopaghetti-footer-wrap p{
    color: #3a3a3a;
  }
  .shopaghetti-main-content, .shopaghetti-footer-wrap p{
    font-family: "Andika New Basic", sans-serif;
  }
  .shopaghetti-main-content{
    font-size: .9rem;
  }

  .shopaghetti-main-wrap,.shopaghetti-footer-wrap{
    display: block;
    padding: 0 1rem;
  }
  .shopaghetti-main-wrap{
    text-align: center;
    padding-bottom: .2rem;
  }
  .shopaghetti-copy-btn{
    color: #fff;
    padding: .7rem 1.5rem;
    border-radius: 2rem;
    border: none;
    outline: none;
  }
  .shopaghetti-footer-wrap{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .shopaghetti-footer-wrap p{
    margin: 0;
  }
  .shopaghetti-footer-wrap .shopaghetti-nav{
    margin: 0;
    padding: 0;
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
  }
  .shopaghetti-nav li{
    list-style: none;
  }
  .shopaghetti-nav li a{
    display: block;
    margin: 0;
    padding: 0;
    width: 1.8rem;
    height: 1.8rem;
    background-position: center center;
    background-repeat: no-repeat;
    margin: .2rem .6rem 0;
  }
  .shopaghetti-nav li:nth-child(1) a{
    background-image: url("data:image/svg xml,<svg class=\'icon\' fill=\'#888\' viewBox=\'0 0 1024 1024\' version=\'1.1\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M832.512 63.488q26.624 0 50.176 10.24t40.96 27.648 27.648 40.448 10.24 49.664l0 641.024q0 26.624-10.24 49.664t-27.648 40.448-40.96 27.648-50.176 10.24l-191.488 0 0-384 63.488 0q26.624 0 45.568-18.944t18.944-45.568-18.944-45.568-45.568-18.944l-63.488 0q2.048-38.912 16.384-52.224t38.912-13.312l66.56 0q27.648 0 41.984-19.968t14.336-44.032-13.824-44.032-42.496-19.968l-110.592 0q-34.816 0-60.928 11.776t-44.032 32.256-26.624 47.104-8.704 57.344l0 45.056-63.488 0q-26.624 0-45.568 18.944t-18.944 45.568 18.944 45.568 45.568 18.944l63.488 0 0 384-320.512 0q-26.624 0-49.664-10.24t-40.448-27.648-27.648-40.448-10.24-49.664l0-641.024q0-26.624 10.24-49.664t27.648-40.448 40.448-27.648 49.664-10.24l641.024 0z\'  p-id=\'26644\'></path></svg>");
  }
  .shopaghetti-nav li:nth-child(2) a{
    background-image: url("data:image/svg xml,<svg class=\'icon\' viewBox=\'0 0 1024 1024\' version=\'1.1\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M958.293333 256c-32.853333 14.933333-68.266667 24.746667-104.96 29.44 37.546667-22.613333 66.56-58.453333 80.213334-101.546667-35.413333 21.333333-74.666667 36.266667-116.053334 44.8C783.786667 192 736.426667 170.666667 682.666667 170.666667c-100.266667 0-182.186667 81.92-182.186667 183.04 0 14.506667 1.706667 28.586667 4.693333 41.813333C353.28 387.84 218.026667 314.88 128 204.373333c-15.786667 26.88-24.746667 58.453333-24.746667 91.733334 0 63.573333 32 119.893333 81.493334 151.893333-30.293333 0-58.453333-8.533333-83.2-21.333333v1.28c0 88.746667 63.146667 162.986667 146.773333 179.626666a180.053333 180.053333 0 0 1-82.346667 2.986667 182.613333 182.613333 0 0 0 170.666667 127.146667 363.562667 363.562667 0 0 1-227.413333 78.506666c-14.506667 0-29.013333-0.853333-43.52-2.56C146.773333 865.706667 243.2 896 346.453333 896 682.666667 896 867.413333 616.96 867.413333 375.04c0-8.106667 0-15.786667-0.426666-23.893333 35.84-25.6 66.56-58.026667 91.306666-95.146667z\' fill=\'#888\' p-id=\'26794\'></path></svg>");
  }
  .shopaghetti-nav li:nth-child(3) a{
    background-image: url("data:image/svg xml,<svg class=\'icon\' viewBox=\'0 0 1024 1024\' version=\'1.1\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M853.333333 170.666667H170.666667c-46.933333 0-85.333333 38.4-85.333334 85.333333v512c0 46.933333 38.4 85.333333 85.333334 85.333333h682.666666c46.933333 0 85.333333-38.4 85.333334-85.333333V256c0-46.933333-38.4-85.333333-85.333334-85.333333z m0 170.666666l-341.333333 213.333334-341.333333-213.333334V256l341.333333 213.333333 341.333333-213.333333v85.333333z\' fill=\'#888\' p-id=\'26734\'></path></svg>");
  }