#nprogress .bar {
  /* background: #311b92 !important; */
  background: #f50057 !important;
  height: 2px !important;
  position: fixed !important;
  z-index: 5000 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #f50057, 0 0 5px #f50057 !important;
}

#nprogress .spinner {
  z-index: 5000 !important;
  top: 10px !important;
  right: 5px !important;
}

#nprogress .spinner-icon {
  border-top-color: #f50057 !important;
  border-left-color: #f50057 !important;
}
